export const FormatFecha = (fecha) => {
  var event = new Date(fecha);
  var options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  return <div>{event.toLocaleDateString("es-ES", options)}</div>;
};

export const FormatFechaDDMMYYYY = (fecha) => {
  if (!fecha) {
    return;
  }
  const ArrFecha = fecha.split("-");
  const lafecha = ArrFecha[2] + "-" + ArrFecha[1] + "-" + ArrFecha[0] + " 00:00:00";

  var event = new Date(lafecha);
  var options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return event.toLocaleDateString("es-ES", options);
};
