import Loading from "./Loading";
import { useState } from "react";
import useTracking from "../hooks/useTracking";
import { Stepper } from "react-form-stepper";
import themeConfig from "../theme/config.json";
import { FormatFecha, FormatFechaDDMMYYYY } from "../components/Libreria";

import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const Listado = () => {
  const { listado, cargando } = useTracking();
  const pasos = {
    paso1: {
      label: "En Preparación",
      active: false,
      completed: false,
    },
    paso2: {
      label: "Pedido Retirado",
      active: false,
      completed: false,
    },
    paso3: {
      label: "Pedido en Ruta",
      active: false,
      completed: false,
    },
    paso4: {
      label: "Pedido Entregado",
      active: false,
      completed: false,
    },
  };
  let codEstado = 0;
  if (listado.wsg_status) {
    switch (listado.wsg_status) {
      case "PRE":
        codEstado = 0;
        break;
      case "RET":
        codEstado = 0;
        break;
      case "REO":
        codEstado = 1;
        break;
      case "RUT":
        codEstado = 2;
        break;
      case "ENT":
        codEstado = 3;
        pasos.paso4.completed = true;
        break;
      case "NEN":
        codEstado = 4;
        pasos.paso4.label = "Entrega Fallida";
        break;
      case "CAN":
        codEstado = -1;
        break;
      default:
    }
  }

  const items = [];
  if (listado.traces) {
    listado.traces.forEach((element) => {
      if (element.date) {
        items.push(
          <li key={element.id}>
            <div className="item">
              <p className="item-descripcion">{element.description}</p>
              {element.substatus && <p>{element.substatus}</p>}
              <small className="item-fecha">{FormatFecha(element.date)}</small>
            </div>
          </li>
        );
      }
    });
  }

  let nombreQuienRecibe = "";
  const images = [];
  if (listado?.imagenes) {
    listado.imagenes.forEach((element) => {
      if (element.cast === "photo" || element.cast === "signature") {
        images.push({
          src: element.image,
          caption: element.cast === "signature" ? element.name : "",
        });
      }
      if (
        element.cast === "text" &&
        (element.name === "No conocen a cliente en domicilio" ||
          element.name === "Nombre de la persona" ||
          element.name === "Persona que recibe")
      ) {
        nombreQuienRecibe = element.image;
      }
    });
  }

  const [index, setIndex] = useState(-1);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index, item) => {
    console.log("index ->", index);
    console.log("item ->", item);
    setIndex(index);
  };
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  return cargando ? (
    <Loading />
  ) : (
    <>
      <div>
        <p>
          <span className="dest-title">Nº Tracking: </span>
          <span className="dest-detalle">{listado.tracking}</span>
        </p>
        <p>
          <span className="dest-title">Nº Orden: </span>
          <span className="dest-detalle">{listado.identifier}</span>
        </p>
        <p>
          <span className="dest-title">Destinatario: </span>
          <span className="dest-detalle">
            {listado.first_name + " " + listado.last_name}
          </span>
        </p>
        <p>
          <span className="dest-title">Dirección de Entrega: </span>
          <span className="dest-detalle">
            {listado.address + " " + listado.complement + " " + listado.commune}
          </span>
        </p>
        <p>
          <span className="dest-title">Teléfono: </span>
          <span className="dest-detalle">{listado.phone}</span>
        </p>
        <p>
          <span className="dest-title">Región: </span>
          <span className="dest-detalle">{listado.region}</span>
        </p>
        <p>
          <span className="dest-title">Fecha estimada de entrega: </span>
          <span className="dest-detalle">
            {FormatFechaDDMMYYYY(listado.fechaCompromiso)}
          </span>
        </p>
        <p>
          <span className="dest-title">Fecha de Entrega: </span>
          <span className="dest-detalle">
            {FormatFechaDDMMYYYY(listado.fechaEntrega)}
          </span>
        </p>
        {listado?.chofer && listado?.chofer !== "" && (
          <p>
            <span className="dest-title">Ruta: </span>
            <span className="dest-detalle">{listado.chofer}</span>
          </p>
        )}
      </div>
      <div className="legend subtitle">
        <span className="dest-detalle">ESTADO ACTUAL: </span>
        <span className="dest-title">{listado.current_status}</span>
      </div>
      <div>
        <Stepper
          steps={[
            { label: pasos.paso1.label },
            { label: pasos.paso2.label },
            { label: pasos.paso3.label },
            {
              label: pasos.paso4.label,
              active: pasos.paso4.active,
              completed: pasos.paso4.completed,
            },
          ]}
          activeStep={codEstado}
          styleConfig={{
            activeBgColor:
              codEstado !== 4
                ? themeConfig.themeColors.stepper.activeBgColor
                : "red",
            completedBgColor: themeConfig.themeColors.stepper.completedBgColor,
            fontWeight: "bold",
            size: "3.5em",
            circleFontSize: "1.5em",
            labelFontSize: "0.9rem",
            borderRadius: "50%",
          }}
        />
      </div>
      <div className="contenedor-items">
        <ul>{items}</ul>
      </div>
      {images && images.length > 0 && (
        <>
          <h5>PRUEBAS DE ENTREGA</h5>
          <div>
            <Gallery
              images={images}
              onClick={handleClick}
              enableImageSelection={false}
            />
            {!!currentImage && (
              /* @ts-ignore */
              <Lightbox
                mainSrc={currentImage.src}
                imageTitle={currentImage.caption}
                mainSrcThumbnail={currentImage.src}
                nextSrc={nextImage.src}
                nextSrcThumbnail={nextImage.src}
                prevSrc={prevImage.src}
                prevSrcThumbnail={prevImage.src}
                onCloseRequest={handleClose}
                onMovePrevRequest={handleMovePrev}
                onMoveNextRequest={handleMoveNext}
              />
            )}
          </div>
        </>
      )}
      {nombreQuienRecibe !== "" && (
        <p>
          <span className="dest-title">RECIBE: </span>
          <span className="dest-detalle">{nombreQuienRecibe}</span>
        </p>
      )}
    </>
  );
};

export default Listado;
