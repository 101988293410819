import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Layout from "./ui/template/layout";
import InicioPage from "./pages/InicioPage";
import SeguimientoPage, {
  loader as SeguimientoLoader,
} from "./pages/SeguimientoPage";
import ErrorPage from "./pages/ErrorPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <InicioPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/tracking/:tracking",
        element: <SeguimientoPage />,
        errorElement: <ErrorPage />,
        loader: SeguimientoLoader,
      },
    ],
  },
]);

const root = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
  root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
